.cards {
  padding: 2rem;
  height: 90px;
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  /* UNDO COMMENTS on POSITION & BOTTOM when contact info can be */
  position: sticky;
  bottom: 0;
}

h1 {
  text-align: center;
}

.cards-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  white-space: nowrap;
  /* gap was 2rem before adding twitter */
  gap: 1rem;
  margin-left: 35px;
}

.interest-item {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 0.5rem; */
  color: var(--text-color);
  font-weight: 400;
}

#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #727171;
  font-weight: lighter;
  padding-top: 20px;
  /* padding-bottom: 20px; */
  cursor: pointer;
}

#contact2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #727171;
  font-weight: lighter;
  /* padding-top: 20px; */
  /* padding-bottom: 20px; */
  cursor: pointer;
}

#contact:hover {
  color: #fff;
}
#contact2:hover {
  color: #fff;
}

#interest-icon {
  height: auto;
  width: 3.5rem;
  color: #fff;
  cursor: pointer;
}

#interest-icon:hover {
  transform: scale(1.2);
}

.interest-item svg {
  width: 2rem;
}

/* .cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
} */

/* .cards-items {
  margin-bottom: 24px;
} */

/* .cards-item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
} */

/* .cards-item-link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
} */

/* .cards-item-pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
} */

/* .fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
} */

/* .cards-item-pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
} */

/* .cards-item-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
} */

/* .cards-item-img:hover {
  transform: scale(1.1);
} */

/* .cards-item-info {
  padding: 20px 30px 30px;
} */

/* .cards-item-text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
} */

@media only screen and (max-width: 960px) {
  .cards-container {
    bottom: 0;
    z-index: 999;
    position: sticky;
  }
}

/* @media only screen and (min-width: 1024px) {
  .interest-items {
    display: flex;
  }
} */

/* @media only screen and (max-width: 1024px) {
  .interest-item {
    margin-bottom: 2rem;
  }
} */
