* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
}

.home {
  display: flex;
  /* could be 90vh height */
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  /* padding-top: 80px; */
}
