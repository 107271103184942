.hero-shows-container {
  /* This css file is not affecting how future/past shows behave. Use HeroShowsFutureImages.css */

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
    url('/src/assets/background.jpg') center center/ cover no-repeat;
  /* background: url('/src/assets/background.jpg') center center/ cover no-repeat; */
  background-position: fixed;
  /* padding-top: 100px; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
  padding-top: 25px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

h1 {
  margin-top: -100px;
  color: #fff;
  font-size: 80px;
}

h2 {
  margin-top: -20px;
  color: #fff;
  font-size: 75px;
}

p {
  display: flex;
  text-align: center;
  font-size: 16px;
}

.album-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.artwork {
  width: 100%;
  height: auto;
  max-width: 310px;
  padding-top: 25px;
  /* object-fit: cover; */
}

.hero-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 32px; */
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
