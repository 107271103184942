.hero-music-container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
    url('/src/assets/background.jpg') center top/ cover no-repeat;
  /* background: url('/src/assets/background.jpg') center center/ cover no-repeat; */
  /* alt way of doing background image: center center w/ background2.jpg 
 + remove  background-attachment: fixed*/
  /* background-position: fixed; */
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  padding-bottom: 25px;
  overflow: hidden;
  margin-top: -80px;
  margin-bottom: -80px;
  top: 0;
}

.album-music-container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1.5px solid #fff;
  padding: 20px;
  border-radius: 8px;
  margin: 25px;
  /* margin-top: px; */
}

.album-music-container:hover {
  transform: scale(1.01);
}

#title:hover {
  cursor: pointer;
}

#title {
  color: #fff;
  font-size: 26px;
}

#title-sub {
  color: #fff;
  font-size: 15px;
}

.artwork {
  width: 100%;
  height: auto;
  max-width: 310px;
}

#ticket-link {
  color: #fff;
  font-size: 1.1rem;
  padding-top: 5px;
}

@media screen and (max-width: 960px) {
  .hero-music-container {
    background-attachment: fixed;
    font-size: 70px;
    padding-top: 100px;
    padding-bottom: 100px;
    /* margin-top: 0px; */
    display: block;
    overflow: scroll;
  }

  .album-music-container {
    /* margin-bottom: 100px; */
  }

  #ticket-link {
    color: #fff;
    font-size: 1.1rem;
    padding-top: 5px;
  }
}

@media screen and (max-width: 768px) {
  .hero-music-container {
    background-attachment: fixed;
    display: block;
    font-size: 50px;
    /* margin-top: -10px; */
  }

  .album-music-container {
    margin: 25px;
  }

  #title {
    color: #fff;
    font-size: 22px;
  }

  #title-sub {
    color: #fff;
    font-size: 15px;
  }

  #ticket-link {
    color: #fff;
    font-size: 1.1rem;
    padding-top: 5px;
  }
}
