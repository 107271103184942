.hero-shows-container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
    url('/src/assets/background.jpg') center top/ cover no-repeat;
  /* background: url('/src/assets/background.jpg') center center/ cover no-repeat; */
  /* alt way of doing background image: center center w/ background2.jpg 
   + remove  background-attachment: fixed*/
  /* background-position: fixed; */
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  /* was overflow */
  overflow-y: hidden;
  margin-top: -80px;
  margin-bottom: -80px;
}

.shows-container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1.5px solid #fff;
  padding: 20px;
  border-radius: 8px;
  margin: 25px;
}

/* .album-music-container:hover {
  transform: scale(1.01);
} */

/* #title:hover {
  cursor: pointer;
} */

#location {
  color: #fff;
  font-size: 1.5rem;
}

#date {
  color: #fff;
  font-size: 1.1;
  padding-bottom: 5px;
}

.flyer {
  width: auto;
  height: 100%;
  max-height: 350px;
}

#ticket-link {
  color: #fff;
  font-size: 1.1rem;
  padding-top: 5px;
}

#ticket-link:hover {
  /* transform: scale(1.01); */
  text-decoration: underline;
  cursor: pointer;
  color: #fff;
}

.ticket-link-hover:visited {
  text-decoration: none;
  color: #fff;
}

@media screen and (max-width: 960px) {
  .hero-shows-container {
    background-attachment: fixed;
    font-size: 1.5rem;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: -150px;
    display: block;
    overflow: scroll;
  }

  #ticket-link {
    color: #fff;
    font-size: 1.1rem;
    padding-top: 5px;
  }
}

@media screen and (max-width: 768px) {
  .hero-shows-container {
    background-attachment: fixed;
    display: block;
    font-size: 1.5rem;
    padding-top: 100px;
    padding-bottom: 150px;
    margin-top: -100px;
  }

  #location {
    color: #fff;
    font-size: 1.5rem;
  }

  #date {
    color: #fff;
    font-size: 1.1rem;
  }

  .flyer {
    width: 100%;
    height: auto;
    max-width: 310px;
    padding: 15px 0;
  }

  #ticket-link {
    color: #fff;
    font-size: 1.1rem;
    padding-top: 5px;
  }
}
