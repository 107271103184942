.future-shows-container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
    url('/src/assets/background.jpg') center top/ cover no-repeat;
  /* background: url('/src/assets/background.jpg') center center/ cover no-repeat; */
  /* alt way of doing background image: center center w/ background2.jpg 
 + remove  background-attachment: fixed*/
  /* background-position: fixed; */
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain; */
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 40px;
}

.album-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .upcoming-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
} */

/* .galleryWrap {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
    url('/src/assets/background.jpg') center center/ cover no-repeat;
  background-position: fixed;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 620px;
  margin: 0 auto;
}

.galleryWrap .single {
  width: 600px;
  cursor: pointer;
}

.galleryWrap .single img {
  max-width: 100%;
}

.galleryWrap .single img:hover {
  transform: scale(1.02);
} */

.hero-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
}

.hero-btns .btn {
  margin: 6px;
}

.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top was 150px */
  /* padding-top: 100px; THIS WAS NOT COMMENTED OUT PRIOR TO 6/19/23 */
}

/* .image {
  width: auto;
  height: 600px;
  border-radius: 10px;
} */

.image {
  /* TRY 60 REM TO SEE IF THIS LOOKS BETTER ON MOBILE */
  width: 100%;
  height: 42rem;
  max-width: auto;
  padding-bottom: 65px;
  /* padding-bottom: 125px; ORIGINAL PRIOR TO 6/19/23 */
  object-fit: cover;
}

.left-arrow,
.right-arrow {
  position: absolute;
  /* top: 90%; */
  top: 50%;
  /* right: 34%; */
  right: 32px;
  /* right: 50px; */
  font-size: 3rem;
  color: #fff;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

/* .left-arrow:hover,
.right-arrow:hover {
  background: #fff;
  color: #242424;
  border-radius: 50%;
  transform: all 0.3s ease-out;
} */

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
    padding: 0px 20px;
  }

  .left-arrow {
    left: -1px;
  }
  .right-arrow {
    right: -1px;
  }
}

@media screen and (max-width: 768px) {
  .future-shows-container {
    margin-top: -115px;
    margin-bottom: -100px;
  }
  .hero-container > h1 {
    font-size: 50px;
    /* margin-top: -100px; */
    /* padding: 0px 20px; */
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    max-width: 100%;
  }

  .image {
    max-width: 310px;
    height: auto;
  }
}
