.hero-bio-container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
    url('/src/assets/background.jpg') center top/ cover no-repeat;
  /* alt way of doing background image: center center w/ background2.jpg 
 + remove  background-attachment: fixed*/
  /* background-position: fixed; */
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  /* padding-bottom: 25px; */
  overflow: hidden;
}

.artwork-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  max-width: 650px;
  padding-top: 25px;
  object-fit: cover;
}

#about {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 0.9rem;
  /* padding-bottom: 0.5rem; */
  width: 100%;
  max-width: 675px;
  padding: 20px 20px;
}

#promo-pic {
  /* height auto and width 100% */
  /* height: auto; */
  /* width: 100%; */
  max-width: 675px;
  object-fit: contain;
  padding: 0 20px;
}

#member-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.9rem;
  max-width: 675px;
  padding: 0 20px;
}

/* .right-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

@media screen and (max-width: 960px) {
  .hero-bio-container {
    font-size: 70px;
    margin-top: -150px;
    padding-top: 100px;
    padding-bottom: 120px;
    padding: 0px 20px;
    overflow: scroll;
  }

  #promo-pic {
    height: auto;
    max-width: 700px;
  }

  /* #member-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 675px;
    padding-bottom: 20px;
  } */
}

@media screen and (max-width: 768px) {
  .hero-bio-container {
    font-size: 50px;
    padding-top: 100px;
    padding-bottom: 130px;
    margin-top: -10px;
    padding: 20px 20px;
  }

  #about {
    max-width: 320px;
  }

  #promo-pic {
    height: auto;
    max-width: 330px;
  }

  /* #member-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    max-width: 320px;
  } */
}
