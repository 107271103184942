.hero-container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
    url('/src/assets/background.jpg') center top / cover no-repeat;
  /* alt way of doing background image: center center w/ background2.jpg 
 + remove  background-attachment: fixed*/
  /* background-position: center; */
  background-attachment: fixed;
  padding-top: 35px;
  height: 100vh;
  /* 100VW is new. give it a shot */
  /* width: 100vw; */
  /* max-width: 1200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  overflow-y: hidden;
  margin-top: -80px;
  margin-bottom: -80px;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.release-details {
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border: 1.5px solid #fff; */
  /* padding: 20px; */
  /* border-radius: 8px; */
}

p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

#upcoming {
  color: #fff;
  font-size: 1.7rem;
  /* font-size: 15px; */
}

#title {
  color: #fff;
  font-size: 26px;
}

.artwork {
  width: 100%;
  height: auto;
  max-width: 25rem;
  margin: 0 25px;
  /* padding-top: 125px;
  object-fit: cover; */
}

.hero-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle,
.fa-record-vinyl,
.fa-headphones,
.fa-soundcloud {
  margin-left: 2px;
}

@media screen and (max-width: 960px) {
  .hero-container {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */

    display: block;
    margin-left: auto;
    margin-right: auto;

    /* font-size: 70px;
    margin-top: -150px; */
    /* background-attachment: fixed; */
    font-size: 70px;
    padding-top: 100px;
    padding-bottom: 100px;
    /* margin-top: 0px; */
    /* display: block; */
    overflow: scroll;
  }

  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .artwork {
    width: 100%;
    height: auto;
    max-width: 20rem;
    /* padding-top: 125px;
    object-fit: cover; */
  }

  .hero-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container {
    background-attachment: fixed;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 50px;
    /* margin-top: -10px; */
  }

  /* .hero-container > p {
    font-size: 30px;
  } */

  .release-details {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    /* padding-top: 100px; */
    /* padding-bottom: 150px; */
    /* margin-top: 100px; */
    /* overflow: scroll; */
  }

  .artwork {
    width: 100%;
    height: auto;
    max-width: 20rem;
    /* padding-top: 125px;
    object-fit: cover; */
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
    /* margin-bottom: 25px; */
  }

  .btn {
    width: 95%;
    /* WAS 100 on 6/20/23 */
  }
}
